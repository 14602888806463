@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    .profileDropdown {
        top: 55px !important;
        left: -215px !important;
        width: 260px;
    }

    .profileOptions {
        margin: 20px 0 0 23px;
        cursor: pointer;
        .profileOption {
            &.logout {
                svg {
                    path {
                        fill: #dc2626;
                    }
                }
            }
        }
    }

    //Common
    .profileOption {
        cursor: pointer;
    }
}
//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
}
