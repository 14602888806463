@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.dashboardNav_navMenu {
    .dashboardNav_label {
        opacity: 0;
    }
}
.dashboardNav_backBtn {
    .dashboardNav_icon {
        svg {
            position: relative;
            left: -3px;
        }
    }
    .dashboardNav_label {
        opacity: 0;
    }
}
.dashboardNav_logo {
    svg {
        width: 115px;
        path {
            &:nth-child(2) {
                opacity: 0;
            }
        }
    }
}

.isExpanded {
    .sideNavLayout_sideBarWrapper {
        .dashboardNav_label {
            opacity: 1;
            transition: opacity 1000ms;
            -webkit-transition: opacity 1000ms;
            -moz-transition: opacity 1000ms;
            -o-transition: opacity 1000ms;
        }
    }
}

.sideNavLayout_sideBarWrapper:hover {
    .dashboardNav_backBtn {
        .dashboardNav_icon {
            left: unset;
        }
    }
    .dashboardNav_label {
        opacity: 1;
        transition: opacity 1000ms;
        -webkit-transition: opacity 1000ms;
        -moz-transition: opacity 1000ms;
        -o-transition: opacity 1000ms;
    }
    .dashboardNav_logo {
        svg {
            width: 130px;
            path {
                &:nth-child(2) {
                    opacity: 1;
                    transition: opacity 1000ms;
                    -webkit-transition: opacity 1000ms;
                    -moz-transition: opacity 1000ms;
                    -o-transition: opacity 1000ms;
                }
            }
        }
    }
}
