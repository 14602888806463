@import "./functions";

.container {
    max-width: 1380px;
    padding: 0 20px;
}

.page-item.active .page-link,
.page-link:hover {
    z-index: unset;
}

.modal-open {
    overflow: hidden !important;
}
.modal-backdrop.show {
    opacity: 0.8;
}

@media (max-width: 991px) {
    #navigation {
        top: #{calculatePercent(74)};
    }
}

@media (min-width: 768px) {
    .container {
        padding: 0 30px;
    }
}

@media (min-width: 992px) {
    .container {
        padding: 0 45px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1660px;
        padding: 0 55px;
    }
}

@media (min-width: 1400px) {
    .container {
        padding: 0 60px;
    }
}

@media (min-width: 1600px) {
    .container {
        padding: 0 75px;
    }
}
