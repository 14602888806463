.flipAnimate {
    // perspective: 1000px;
    span.indaiText {
        position: relative;
        display: inline-block;
        padding: 0;
        transition: transform 0.3s;
        transform-origin: 50% 0;
        transform-style: preserve-3d;
        color: #202124 !important;
    }

    span.indaiText:before {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        content: attr(data-hover);
        transition: color 0.3s;
        transform: rotateX(-90deg);
        transform-origin: 50% 0;
        text-align: left;
    }

    &:hover span.indaiText,
    &:focus span.indaiText {
        transform: rotateX(90deg) translateY(-22px);
    }

    &:hover span.indaiText:before,
    &:focus span.indaiText:before {
        //   color: rgb(210, 73, 54);
        color: #1053ff;
    }
}
