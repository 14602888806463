@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    position: fixed;
    width: 100%;
    background: #ffffff;
    z-index: 100;
    top: 0;

    .mainWrapper {
        height: 65px;
        .logoWrapper {
            cursor: pointer;
            svg {
                width: 100%;
                height: 55px;
            }
        }

        .languageDropdown {
            top: 55px !important;
            .languageSubmenuWrapper {
                .languageSubmenu {
                    margin: 20px 20px;
                }
            }
        }

        .dropdownMenuList {
            width: 270px;

            .subMenuItems {
                button {
                    &:hover {
                        span {
                            color: #2355fc;
                        }
                    }
                }
            }
        }

        .menuContainer,
        .dropdownToggleClassName {
            > button {
                position: relative;
                &::after {
                    position: absolute;
                    bottom: 0;
                    content: "";
                    width: 0;
                    height: 2px;
                    background: #2355fc;
                    transition: width 0.3s;
                }
                &:hover {
                    &::after {
                        width: 100%;
                    }
                    span {
                        color: #2355fc;
                    }
                }
            }

            &.active {
                button {
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
    }

    //Common style footer both mob and desktop
    .languageSubmenuWrapper {
        list-style: none;
        padding: 0;

        .languageSubmenu {
            cursor: pointer;

            display: flex;
            flex-direction: column;

            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                color: #3554fa;
            }
            &.active {
                color: #3554fa;
            }
        }
    }

    .appDrawMenu {
        display: none;
    }
}
//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
    .main {
        .mainWrapper {
        }
    }
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
    .main {
        .mainWrapper {
            .logoWrapper {
                svg {
                    width: 100%;
                    height: 40px;
                }
            }

            .hamburgerMenu {
                span {
                    display: block;
                    width: 25px;
                    height: 2px;
                    margin-bottom: 5px;
                    position: relative;
                    background-color: $dark;
                    border-radius: 3px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &.open {
                    span {
                        margin-bottom: 0;
                        transition: transform 0.5s ease;
                        &:first-child {
                            top: 2px;
                            transform: rotate(45deg);
                        }
                        &:nth-child(2) {
                            visibility: hidden;
                        }
                        &:last-child {
                            top: -2px;
                            width: 100%;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }

            .languageDropdown {
                top: 55px !important;
                .languageSubmenuWrapper {
                    list-style: none;
                    padding: 0;

                    .languageSubmenu {
                        padding: 10px 20px;
                    }
                }
            }
        }

        .appDrawMenu {
            height: 0;
            transition: transform 0.5s ease;
            &.isOpen {
                display: block;
                height: 100%;
                min-height: 100vh;
                overflow-y: scroll;
                transition: transform 0.5s ease;

                .appDrawMenuOptionListWrapper {
                    .appDrawMenuOption {
                        border-bottom: 1px solid rgba(237, 245, 254, 0.9);
                        padding: 25px 20px;
                    }
                }
            }
        }
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .main {
        .mainWrapper {
            height: 55px;
            .loginBtn {
                button {
                    width: 96px;
                    height: 44px;
                    padding: 0;
                }
            }
            .logoWrapper {
                svg {
                    width: 100%;
                    height: 40px;
                }
            }

            .hamburgerMenu {
                span {
                    display: block;
                    width: 25px;
                    height: 2px;
                    margin-bottom: 5px;
                    position: relative;
                    background-color: $dark;
                    border-radius: 3px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &.open {
                    span {
                        margin-bottom: 0;
                        transition: transform 0.5s ease;
                        &:first-child {
                            top: 2px;
                            transform: rotate(45deg);
                        }
                        &:nth-child(2) {
                            visibility: hidden;
                        }
                        &:last-child {
                            top: -2px;
                            width: 100%;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }

            .languageDropdown {
                top: 55px !important;
                .languageSubmenuWrapper {
                    list-style: none;
                    padding: 0;

                    .languageSubmenu {
                        padding: 10px 20px;
                    }
                }
            }
        }

        .appDrawMenu {
            height: 0;
            transition: transform 0.5s ease;
            &.isOpen {
                display: block;
                height: 100%;
                min-height: 100vh;
                overflow-y: scroll;
                transition: transform 0.5s ease;

                .appDrawMenuOptionListWrapper {
                    .appDrawMenuOption {
                        border-bottom: 1px solid rgba(237, 245, 254, 0.9);
                        padding: 25px 20px;

                        &.langDropDown,
                        &.subMenuOption {
                            &.isOpen {
                                padding-bottom: 0;
                            }
                            svg {
                                width: 18px;
                                height: 18px;

                                path {
                                    fill: #2355fc;
                                }
                            }

                            .languageSubmenuWrapper,
                            .subMenuWrapper {
                                list-style: none;
                                padding: 0;
                                padding-top: 10px;

                                .languageSubmenu {
                                    padding: 20px 0px;
                                }

                                .languageSubmenu,
                                .subMenu {
                                    padding: 20px 0px;
                                    cursor: pointer;
                                    position: relative;

                                    display: flex;
                                    flex-direction: column;
                                    border-bottom: 1px solid rgba(237, 245, 254, 0.9);

                                    &:last-child {
                                        border: none;
                                        padding-bottom: 0;
                                    }

                                    .selectDot {
                                        width: 20px;
                                        height: 20px;
                                        border: solid 1px rgba(222, 222, 222, 1);
                                        border-radius: 50%;
                                        position: absolute;
                                        top: 0;
                                        right: 12px;
                                        bottom: 0;
                                        margin: auto 0;
                                    }
                                    &.active {
                                        .selectDot {
                                            border: solid 1px #3554fa;
                                            &::after {
                                                content: "";
                                                width: 14px;
                                                height: 14px;
                                                border-radius: 50%;
                                                background-color: #3554fa;
                                                margin: auto;
                                                position: absolute;
                                                left: 0;
                                                right: 0;
                                                bottom: 0;
                                                top: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .downloadBanner {
                        margin: auto;
                        margin-top: 60px;
                    }
                }
            }
        }
    }
}
