.mobileFilter_layout {
    .filterLinks {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        padding: 10px;
        color: #ffffff;
        margin-top: 10px;
        // border-top: 1px solid #FFFFFF;
        border-bottom: 0.5px solid #ffffff;

        &.active {
            border-radius: 8px;
            background-color: #0f53fb;
            border-bottom: none;
        }

        svg {
            margin-right: 10px;

            path {
                fill: #ffffff;
                opacity: 1;
            }
        }
    }
}
