@import "./bs-menu.scss";
@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
    .loginSignupBtn {
        height: 100%;

        .handlelogintoggle {
            button {
                width: 178px;
                height: 39px;
            }
        }
    }
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
    .loginSignupBtn {
        height: 100%;

        .handlelogintoggle {
            button {
                width: 178px;
                height: 39px;
            }
        }
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
}

#topnav {
    transform: translateZ(1000px);
    z-index: 100;
    .container {
        height: 100%;
    }
}

#topnav .navigation-menu > li > a {
    font-family: var(--font-medium);
    text-transform: none;
    font-weight: normal;
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #000000;
    font-weight: bold;
}

#topnav .navigation-menu {
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: flex-end;
}

.siteLogo {
    position: relative;
    .logo {
        width: 100%;
        padding: 3px 0;
        cursor: pointer;
    }
}
.languageActive {
    color: #135bf8;
}

.loginSignupBtn {
    font-size: 14px;
    display: flex;
    align-items: center;
    float: right;
}
.premiumLaunchBanner {
    display: flex;
    align-items: center;
    float: right;
    height: 100%;
    margin: 0 5px 0 12px;
    cursor: pointer;
}
#topnav #navigation {
    max-height: 0;
    height: 0;
}
#topnav #navigation.isNavOpen {
    max-height: 100vh;
    // height: 100vh;
    height: 93vh;
    overflow-y: scroll;
}

@media (max-width: 992px) {
    .siteheader {
        min-height: 55px;
        // height: 55px;
    }
    #topnav .container {
        display: flex;
        justify-content: space-between;
        .sitelogo_menuextras_wrapper {
            display: flex;
            align-items: center;
        }
        .menu-extras {
            order: 0;
            margin-right: 16px;
        }
        .loginSignupBtn {
            order: 2;
        }
        .siteLogo {
            order: 1;
            width: 100px;
            margin-right: 25px;
        }
    }
    .siteLogo {
        max-width: 100px;
        top: 2px;
        left: -5px;
        .logo {
            line-height: 45px;
            svg {
                vertical-align: text-bottom;
            }
        }
    }
    // #topnav .navigation-menu {
    //     margin-bottom: 100px;
    // }

    #topnav .navigation-menu > li {
        border-bottom: solid 1px rgba(237, 245, 254, 0.9);
        margin: 5px 10px;
        &:nth-last-child(1) {
            border-bottom: none;
        }
    }
    #topnav .navigation-menu > li > a {
        color: #000000 !important;
        padding: 15px 10px !important;
        line-height: calc(#{calculatePercent(74)} - 35px);
    }

    .serviceSubmenu {
        transition: max-height 300ms;
        -webkit-transition: max-height 300ms;
        -moz-transition: max-height 300ms;
        max-height: 0;
        overflow: hidden;
        padding-left: 25px;
        margin-top: -5px;
        li {
            margin: 15px 0;
            list-style-type: none;
            a {
                color: #000000;
                font-weight: bold;
                font-size: 13px;
                font-family: var(--font-medium);
            }
        }
    }
    .hasSubmenu {
        position: relative;
        &.subActive {
            .serviceSubmenu {
                max-height: 400px;
            }
        }
    }
    .hasSubmenu > ul {
        background-color: rgba(237, 245, 254, 0.9);
        margin-left: -10px;
        margin-right: -10px;
    }
    .hasSubmenu > ul.languageSubmenu {
        background-color: #ffffff;
        margin-left: 0;
        margin-right: 0;
    }

    #topnav #navigation {
        overflow: auto;
        display: block;
        transition: max-height 500ms;
        -webkit-transition: max-height 500ms;
        -moz-transition: max-height 500ms;
        border: none !important;
        // height: 100vh !important;
        // max-height: 100vh !important;
    }

    .languageSubmenu {
        padding: 0 10px 100px;
        list-style: none;
        position: fixed;
        top: calc(0.09113 * var(--screen-height));
        background: #ffffff;
        width: 100%;
        left: 0;
        right: 0;
        overflow-y: scroll;
        display: none;
        li {
            padding: 12px 12px;
            margin: 5px 0;
            list-style-type: none;
            border-bottom: solid 1px rgba(237, 245, 254, 0.9);
            position: relative;
            &:nth-last-child(1) {
                border-bottom: none;
            }
            span {
                display: block;
            }

            .selectDot {
                width: 20px;
                height: 20px;
                border: solid 1px rgba(222, 222, 222, 1);
                border-radius: 50%;
                position: absolute;
                top: 0;
                right: 12px;
                bottom: 0;
                margin: auto 0;
            }
            &.active {
                color: #125bf8;
                .selectDot {
                    border: solid 1px #3554fa;
                    &::after {
                        content: "";
                        width: 14px;
                        height: 14px;
                        border-radius: 50%;
                        background-color: #3554fa;
                        margin: auto;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                    }
                }
            }
        }
    }
    .profilePic {
        padding-top: 5px;
    }
}

.siteheader.headerOnCityPageBanner {
    background-color: rgba(0, 0, 0, 0) !important;
    position: absolute !important;
    top: 0 !important;
    z-index: 5 !important;
}
#topnav.headerOnCityPageBanner .navigation-menu > li > a {
    color: var(--white-color);
    cursor: pointer;
}
#topnav.headerOnCityPageBanner .navbar-toggle span {
    background-color: var(--white-color);
}

#topnav.headerOnCityPageBanner button {
    background-color: var(--white-color);
    color: var(--primary-color);
}

@media (min-width: 992px) {
    #topnav.headerOnCityPageBanner .navigation-menu > li > a {
        svg {
            path {
                fill: var(--white-color);
            }
        }
    }
    .siteheader {
        background-color: #ffffff !important;
    }
    .siteLogo {
        max-width: 140px;
        left: -7px;
        top: 5px;
    }
    #topnav #navigation {
        height: auto !important;
    }
    #topnav .navigation-menu {
        background-color: rgba(17, 92, 250, 0);
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #topnav .navigation-menu > li > a {
        margin-top: 0;
        margin-bottom: 0;
        color: #000000;
        font-weight: bold;
        font-size: 16px;
        // line-height: calc(#{calculatePercent(74)} - 50px);
    }
    #topnav.home_header {
        background: rgb(8, 8, 8) !important;
        background: linear-gradient(180deg, rgba(8, 8, 8, 0.719765406162465) 0%, rgba(0, 0, 0, 0) 100%) !important;
        position: absolute;
        margin-top: 0 !important;
        .hasSubmenu {
            svg {
                path {
                    fill: #ffffff;
                }
            }
        }
    }
    #topnav.home_header.headerNotonBanner {
        background: #ffffff !important;
        .hasSubmenu {
            svg {
                path {
                    fill: rgb(58, 82, 252);
                }
            }
        }
    }
    .hasSubmenu {
        svg {
            display: inline-block;
            width: 12px;
            height: 10px;
            margin-left: 2px;
        }
    }
    .hasSubmenu {
        position: relative;
        &:hover {
            .serviceSubmenu {
                visibility: visible;
            }
        }
    }

    .hasSubmenu.languagesList {
        svg {
            width: 44px;
            height: 44px;
        }
    }
    .showlangMenu {
        .languageSubmenu {
            visibility: visible;
        }
    }
    .languageSubmenu {
        visibility: hidden;
        background-color: #fff;
        position: fixed;
        // top: 60px;
        top: 80px;
        -webkit-box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.1);
        padding: 0;
        width: 170px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        li {
            padding: 5px 12px;
            margin: 5px 0 10px;
            list-style-type: none;
            span {
                display: block;
            }
            .selectDot {
                display: none;
            }
        }
        li:hover {
            color: #135bf8 !important;
            cursor: pointer;
        }
        li.active {
            color: #135bf8 !important;
        }
        .languageMenuTitle {
            display: none;
        }
    }

    .serviceSubmenu {
        visibility: hidden;
        background-color: #fff;
        position: absolute;
        top: 60px;
        -webkit-box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.1);
        padding: 0;
        width: 170px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        li {
            padding: 5px 12px;
            margin: 5px 0;
            list-style-type: none;
            a {
                color: #000000 !important;
                font-size: 14px;
                font-family: var(--font-medium);
            }
            a:hover {
                color: #135bf8 !important;
            }
        }
    }
}

#topnav .navbar-toggle .lines {
    margin-left: 0;
    transition: all 0.2ms;
}

#topnav button {
    transition: all 0.2ms;
    background-color: #08123b;
}

#topnav {
    .serviceSubmenu {
        li {
            a {
                color: #000000 !important;
                &:hover {
                    color: #2f55d4 !important;
                }
            }
        }
    }
}

#topnav.home_header {
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    box-shadow: none !important;
    li > a {
        color: rgba(255, 255, 255, 1);
    }

    .navbar-toggle .lines span {
        background-color: white;
    }

    button {
        background-color: #ffffff;
        color: #255fef;
    }
    @media (max-width: 991px) {
        #navigation,
        .navigation-menu {
            background-color: #ffffff;
        }
    }
}
#topnav.home_header.headerNotonBanner {
    position: fixed;
    background: #ffffff;
    li > a {
        color: #000000;
        font-weight: bold;
    }

    .navbar-toggle .lines span {
        background-color: #3c4858;
    }

    button {
        background-color: #1053ff;
        color: #ffffff;
    }
}

#topnav button {
    background-color: #195af7;
}

.profilePic {
    cursor: pointer;
}
.UserProfileDropdown {
    .dropdown {
        .dropdown-menu {
            // margin-top: 20px;
            top: calc(100% + 4px) !important;
            transition: none !important;
            left: -208px !important;
            // transform: translate3d(-208px, calc(#{calculatePercent(74/2)} - 8px), 0px) !important;
        }
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .showlangMenu {
        .languageSubmenu {
            position: unset;
            padding: 0;
            list-style: none;
            background: #ffffff;
            width: 100%;
            overflow-y: scroll;
            display: block;

            li {
                padding: 12px 12px;
                margin: 5px 0;
                list-style-type: none;
                border-bottom: solid 1px rgba(237, 245, 254, 0.9);
                position: relative;
                &:nth-last-child(1) {
                    border-bottom: none;
                }
                &:last-child {
                    margin-bottom: 50px;
                }
                span {
                    display: block;
                }

                .selectDot {
                    width: 20px;
                    height: 20px;
                    border: solid 1px rgba(222, 222, 222, 1);
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    right: 12px;
                    bottom: 0;
                    margin: auto 0;
                }
                &.active {
                    color: #125bf8;
                    .selectDot {
                        border: solid 1px #3554fa;
                        &::after {
                            content: "";
                            width: 14px;
                            height: 14px;
                            border-radius: 50%;
                            background-color: #3554fa;
                            margin: auto;
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            top: 0;
                        }
                    }
                }
            }
        }
    }

    .navigation-menu {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: initial !important;

        .downloadAppIcon {
            margin-top: 60px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

#overlay {
    position: fixed;
    // display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    cursor: pointer;
}
.downloadAppQrCode {
    position: absolute;
    top: 10%;
    right: 10%;
    z-index: 9;

    .wrapper {
        position: relative;
        background-color: #fff;
        border-radius: 8px;
        padding: 40px;

        .closeBtn {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }

    .QrCode {
        .title {
            max-width: 170px;
            text-align: center;
            margin-bottom: 10px;
        }
    }
}

@media (min-width: 1300px) {
    .navigation-menu {
        max-width: 70% !important;
        margin-left: auto !important;
        .languagesList {
            margin-left: auto !important;
        }
    }
}
@media (min-width: 992px) {
    .navigation-menu {
        max-width: 80%;
        margin-left: auto !important;
        .languagesList {
            margin-left: auto !important;
        }
    }
}
