.mostSearchedLinks {
    text-align: center;
    text-transform: uppercase;

    &.active {
        padding-bottom: 30px;
    }

    a {
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            padding-right: 5px;
        }
        svg {
            path {
                fill: #ffffff;
                opacity: 1;
            }
        }
    }
}

.Footer_mobile-link-container {
    opacity: 0;
    height: 0;
    &.active {
        opacity: 1;
        height: auto;
    }
}

.nav {
    display: flex;
    position: relative;
    max-width: 1100px;
    background-color: #20294e;
    border-radius: 8px;
    opacity: 0;
    height: 0;
    margin-left: auto;
    margin-right: auto;

    &.active {
        opacity: 1;
        height: auto;

        a {
            svg {
                path {
                    fill: #ffffff;
                    opacity: 1;
                }
            }
        }
    }
    .is-active {
        background-color: #0f53fb;
    }

    .horizontalDivider:not(:last-child) {
        border-left: 0.5px solid #ffffff;
        height: 20px;
        margin-bottom: auto;
        margin-top: auto;
        opacity: 0.4;
    }
}

.nav-item {
    color: #ffffff;
    padding: 20px;
    text-decoration: none;
    transition: 0.3s;
    z-index: 1;
    font-size: 14px;
    position: relative;
    border-radius: 8px;
    text-align: center;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        padding-right: 7px;
    }

    &:before {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: #dfe2ea;
        border-radius: 8px 8px 0 0;
        opacity: 0;
        transition: 0.3s;
    }
}

.nav-item:hover {
    color: #ffffff;
}

.nav-indicator {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    transition: 0.4s;
    height: 5px;
    z-index: 1;
    border-radius: 8px;
}

@media (max-width: 580px) {
    .nav {
        overflow: auto;
    }
}
