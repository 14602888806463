@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main {
    margin-top: 80px;
    ul {
        list-style: none;
        padding-inline-start: 0;
    }
    li {
        cursor: pointer;
    }
    li,
    li a {
        display: flex;
        align-items: center;
    }
}

@media (max-width: $mobileBreakpointMaxWidth) {
    .bottomNavbarWrapper {
        @include flex-center;
        justify-content: space-between;
        height: 100%;

        .menuItem {
            @include flex-center;
            width: 100%;
            height: 100%;
            position: relative;
            &.active {
                .icon {
                    span {
                        color: #2355fc;
                    }
                }
                &::before {
                    content: "";
                    display: block;
                    width: 52px;
                    height: 4px;
                    background-color: #2355fc;
                    position: absolute;
                    bottom: 0;
                    border-top-right-radius: 4px;
                    border-top-left-radius: 4px;
                    opacity: 1;
                    transition: opacity 1500ms;
                }
            }

            .icon {
                display: flex;
                flex-direction: column;
                align-items: center;
                i {
                    svg {
                        path {
                            // fill: #2355fc;
                            // stroke: #2355fc;
                        }
                    }
                    &:not(.activeIcon) {
                        svg {
                            path {
                                // fill: #999999;
                                // stroke: #999999;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $desktopBreakpointMinWidth) {
    .navMenu {
        margin-bottom: 40px;
        margin-bottom: 15px;
        padding-left: 10px;
        padding-top: 9px;
        padding-bottom: 9px;

        @include flex-center;
        justify-content: flex-start;
        position: relative;
        &.active {
            &::before {
                content: "";
                display: block;
                width: 4px;
                height: 42px;
                background-color: #ffffff;
                position: absolute;
                left: -15px;
                border-bottom-right-radius: 4px;
                border-top-right-radius: 4px;
                opacity: 1;
                transition: opacity 1500ms;
            }
        }

        .icon {
            transform: translate(0px, -2px);
            &.postLoadLory {
                @include flex-center;
                background-color: #ffffff;
                width: 50px;
                height: 50px;
                border-radius: 6px;
            }
        }

        .label {
            font-size: var(--font-medium);
            color: #ffff;
            padding-left: 10px;
        }
    }
    .main {
        // Load/Lorry posting button
        .postingIcon {
            margin: 0 -5px;
            padding-bottom: 30px;

            .postingMenuDropdownContainer {
                background-color: #ffffff;
                border-radius: 6px;
                width: 100%;
            }
            .dropdownMenuItemClassName {
                top: 0px !important;
                padding: 10px;
                border-radius: 6px;
                left: 110%;
                box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
            }

            .dropdownToggleClassName {
                display: block;
                width: 100%;
            }

            .postingLabelContainer {
                color: var(--primary-color);
                font-family: var(--font-medium);

                .postingLabel {
                    top: 15px;
                    position: absolute;
                }
            }

            .postingMenuItem {
                .postingMenuItemBtn {
                    font-family: var(--font-medium);
                    font-size: calcRem(16);
                    width: 260px;
                    height: 50px;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    background-color: #ffffff;
                    color: var(--brownish-grey);

                    span {
                        background-color: #e9e9e9;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 6px;
                        svg {
                            path {
                                stroke: #666666;
                                fill: #666666;
                            }
                        }
                    }

                    &:hover {
                        background-color: #2355fc;
                        color: #ffffff;
                        span {
                            background-color: #ffffff;
                            transition: background-color 500ms;
                            svg {
                                path {
                                    stroke: #2355fc;
                                    fill: #2355fc;
                                }
                            }
                        }
                    }
                }
            }
        }

        .userProfile {
            padding-left: 2.5px;
            position: absolute;
            bottom: 50px;
        }
    }
}
