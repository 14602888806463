@import "@vahak/core/dist/scss/functions";
@import "@vahak/core/dist/scss/variables";

.imageContainer {
    width: max-content;
    img {
        border-radius: 50%;
        object-fit: cover;
    }

    .initialContainer {
        background-color: #212121;
        color: #ffffff;
        text-transform: uppercase;
        text-align: center;
        font-size: 15px;
        letter-spacing: 1px;
        border-radius: 50%;
    }
}
