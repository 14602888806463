//
// variables.scss
//

// Color
$white: #ffffff;

$primary: #2f55d4; //2f55d4
$secondary: #5a6d90;
$success: #2eca8b;
$warning: #f17425;
$info: #17a2b8;
$danger: #e43f52;
$dark: #3c4858;
$black: #161c2d;
$muted: #8492a6;
$light: #f8f9fc;

// Gray
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;

// Blue
$blue-1000: #182b63;

// Overlay
$overlay: rgba($dark, 0.7);
$gradident-overlay: rgba($primary, 0.6);
$bg-overlay-white: rgba($white, 0.5);

//Body Background
$shadow: 0 0 3px rgba($dark, 0.15);
// $footer: lighten($black, 6%);
$footer: #08123b;

// Base font
$font-size-base: 16px;

$font-family-base: "Nunito", sans-serif;
$font-family-secondary: "Nunito", sans-serif;

//Color Picker/Switcher
$green: #6dc77a;
$red: #ff5b69;
$skyblue: #32c2ec;
$skobleoff: #0f7173;
$cyan: #00c9a7;

$colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "warning": $warning,
    "info": $info,
    "danger": $danger,
    "dark": $dark,
    "muted": $muted,
    "light": $light,
    "footer": $footer
);

$fontWeights: (
    "extraBold": 1000,
    "bold": 850,
    "semiBold": 600,
    "medium": 550,
    "normal": 400
);
$refViewportHeight: 812;
$refViewportHeightMultiplicator: 0.00123152709; // 1/$refViewportHeight
$refViewportWidth: 375;
$refViewportWidthMultiplicator: 0.002666666667; // 1/$refViewportWidth

$mobileBreakpointMaxWidth: 991px;
$desktopBreakpointMinWidth: 992px;

$desktopBreakpoint: 992px;
$mediumScreenBreakpoint: 768px;
$mobileBreakpoint: 576px;

$fontWeights: (
    "extraBold": 1000,
    "bold": 850,
    "lightBold": 700,
    "semiBold": 600,
    "medium": 550,
    "normal": 400
);

// Disabled Btn Color
$primary-disabled: #ffffff;
$secondary-disabled: #afc4ff;
$danger-disabled: #ff9090;
$green-disabled: #999;
$darkgray-disabled: #999;

$primary-disabled-bg: #afc4ff;
$secondary-disabled-bg: #eef5fe;
$danger-disabled-bg: #ffe3e3;
$green-disabled-bg: #f6f6f6;
$darkgray-disabled-bg: #f6f6f6;

// New color scheme
$blue-1000: #182b63;
$blue-900: #223d8d;
$blue-700: #1f41b4;
$blue: #2355fc;
$blue-500: #4e85ff;
$blue-400: #70a7ff;
$blue-300: #98caff; // primary color - disabled
$blue-200: #bfdfff;
$blue-100: #dbefff; // primary background
$blue-50: #e9f5ff;

// red
$red-700: #991b1b;
$red-600: #b91c1c; // text error
$red: #dc2626;
$red-400: #ef4444;
$red-300: #f87171;
$red-200: #fca5a5;
$red-100: #fecaca;

// green
$green-700: #0f863c;
$green-600: #0ea546;
$green-500: #19ce5c;
$green-400: #42e67e;
$green-300: #80f5aa;
$green-200: #b8facf;
$green-100: #dafee6;

// yellow
$yellow: #ffb700;
$yellow-500: #ffd71b;
$yellow-400: #ffe846;
$yellow-300: #fff485;
$yellow-200: #fff9c5; // accent background
$yellow-100: #fffeea;

// orange
$orange: #f56700;
$orange-500: #ff840a;
$orange-400: #ffa132;
$orange-300: #ffc56d;
$orange-200: #ffdea5;
$orange-100: #fff0d3;

// purple
$purple: #5938fb;
$purple-light: #dcd4ff;

// grey
$grey-1000: #191919; // primary text color
$grey-900: #383838;
$grey-800: #434343;
$grey-700: #515151; // secondary text color
$grey-600: #666666;
$grey-500: #818181;
$grey-400: #a4a4a4; // placeholder text color
$grey-300: #c8c8c8; // disabled text color
$grey-200: #e3e3e3; // subtle border color
$grey-150: #ededed;
$grey-100: #f7f7f7;
$grey-50: #f9fafb;
