@font-face {
    font-family: "Inter-Regular";
    src: local("Inter-Regular"), url("../fonts/Inter/Inter-Regular.woff2") format("woff2");
    font-display: swap;
}
@font-face {
    font-family: "Inter-Medium";
    src: local("Inter-Medium"), url("../fonts/Inter/Inter-Medium.woff2") format("woff2");
    font-display: swap;
}
@font-face {
    font-family: "Inter-Bold";
    src: local("Inter-Bold"), url("../fonts/Inter/Inter-Bold.woff2") format("woff2");
    font-display: swap;
}
@font-face {
    font-family: "Inter-SemiBold";
    src: local("Inter-SemiBold"), url("../fonts/Inter/Inter-SemiBold.woff2") format("woff2");
    font-display: swap;
}
@media (-webkit-device-pixel-ratio: 1.5) {
    html {
        /* font-size: 13px;
        zoom: 0.8; */
    }
}
