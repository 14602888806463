.categoryHeading {
    font-size: 16px;
    color: #ffffff;
    opacity: 0.4;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-top: 36px;
    margin-bottom: 0;
    text-transform: uppercase;
    padding-left: 10px;
}
.mostSearchedCategoryList {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 1100px;

    &.inactive {
        height: 0;
        opacity: 0;
        margin-bottom: 0;
        visibility: hidden;
    }

    &.active {
        height: auto;
        opacity: 1;
        visibility: visible;
    }
    li {
        list-style: none;
        padding: 10px 10px 10px 10px;
        width: 180px;
        text-transform: capitalize;
        a {
            color: inherit !important;
        }

        &.majorRoutes {
            width: 225px;
        }
    }
}

.show_more {
    h4 {
        font-size: 16px;
        padding-left: 10px;
        a {
            color: #ffffff;
        }
    }
}

@media (max-width: 767px) {
    .categoryHeading {
        padding-left: 10px;
        padding-top: 36px;
        margin-bottom: 0;
    }
    .mostSearchedCategoryList {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-left: auto;
        margin-right: auto;
        max-width: 900px;

        &.inactive {
        }

        &.active {
        }
    }
}
