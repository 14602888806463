@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";
@import "@vahak/core/dist/scss/mixins";

@mixin main {
    display: flex;
}

.scrollOff {
    overflow: hidden !important;
}
.ImageContainer {
    display: flex;
    justify-content: center;
}

.topContainer {
    width: 100%;
    z-index: 2;
    transition: all 400ms;
}

.desktopPage {
    .searchCardContainer > div {
        border-radius: 12px;
    }
    .sticky {
        .cover {
            display: none !important;
        }
        // top: -54px;
        .searchCardContainer {
            margin-top: 30px;
            width: 100%;
            padding: 0;
            border-radius: 0;
            box-shadow: 0px 10px 20px #cdcdcd29;
        }
        .searchCardContainer > div {
            border-radius: 0px;
        }
    }
}

.transporterSearch__results__mobile {
    .cardContainer {
        row-gap: 22px;
        // .profileCard {
        //     margin-bottom: 22px;
        // }
        .profileCard {
            width: 100%;
        }
    }
    .alignPaginationDiv {
        margin-top: 30px;
    }
}

.transporterSearch__results__desktop {
    .cardContainer {
        margin-bottom: -40px;
        .profileCard {
            margin-bottom: 40px;
            margin-right: 30px;
        }
        .profileCard {
            width: calc((100% - 90px) / 3);
        }
    }
    .alignPaginationDiv {
        margin-top: 60px;
    }
}

.TransporterSearch__Overlay {
    overflow: hidden;
}
.mobilePage .TransporterSearch__Overlay {
    height: 315px;
}
.desktopPage .TransporterSearch__Overlay {
    height: 420px;
}

.outLine {
    border: none;
    outline: none;
}
.paginationIcon {
    border-radius: "50%";
    border-color: "#80868B";
    height: "42px";
    width: "42px";
    opacity: "0.4";
    margin-right: "8px";
}
.mainDivAlign {
    @include main;
    align-items: center;
}
.alignPaginationDiv {
    @include main;
    align-items: center;
    justify-content: center;
}
.MobileBanner {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
}

// Edit Search Modal
.EditSearchModal__modal {
    &.fade .modal-dialog {
        transform: translate(0, -76px);
    }
    &.show .modal-dialog {
        transform: translate(0, 76px);
    }
}

// ProfileCard
@mixin alignOption($direction) {
    display: flex;
    flex-direction: direction;
}
.alignDiv {
    display: flex;
    align-items: center;
}

.profileCard__mobile__container {
    position: relative;
    padding: 0 13px;
    .ribbon {
        // line-height: 25px;
        // height: 25px;
        margin-left: calc(-13px - 6.5px);
        padding: 3px 6px;
        font-size: calcRem(10);
        &::before {
            bottom: -6.3px;
            border-top-width: 6.3px;
            border-left-width: 6.5px;
        }
    }
    .profileCard__topSection {
        margin-top: 20px;
    }
    .profileCard_btnContainer {
        margin-top: 18px;
        margin-bottom: 19px;
    }
}
.profileCard__desktop__container {
    position: relative;
    padding: 0 20px;
    .ribbon {
        // line-height: 34px;
        // height: 34px;
        margin-left: calc(-20px - 13px);
        padding: 5px 6px;
        font-size: calcRem(11);
        &::before {
            bottom: -10.8px;
            border-top-width: 10.8px;
            border-left-width: 13px;
        }
    }
    .profileCard__topSection {
        margin-top: 30px;
    }
    .profileCard_btnContainer {
        margin-top: 20px;
        margin-bottom: 29px;
        .profileCard_btn {
            padding: 0 15px;
        }
    }
}

.cardBtn {
    padding: 5px 10px;
    outline: none;
    text-decoration: none;
    font-size: #{calcRem(16)};
    letter-spacing: 0.5px;
    transition: all 0.3s;
    font-weight: map-get($map: $fontWeights, $key: "semiBold");
    border-radius: 10px;
    white-space: nowrap;
}
.ribbon {
    position: absolute;
    top: -1px;
    display: block;
    width: fit-content;
    text-align: start;
    background: #fa4a5b;
    // position: relative;
    opacity: 1;
    font-family: var(--font-medium);
    &::before,
    &::after {
        content: "";
        position: absolute;
    }
    &::before {
        height: 0;
        width: 0;
        left: 0;
        border-top-style: solid;
        border-top-color: #d12a3b;
        border-left-style: solid;
        border-left-color: transparent;
    }

    .topRated {
        color: #ffffff;
        opacity: 0.9;
        line-height: 1;
    }
}

.placeName {
    color: #80868b;
    opacity: 0.9;
    line-height: 18px;
    text-transform: uppercase;
    @include textEllipsis(1);
}
.companyName {
    color: #181d20;
    opacity: 1;
    font-weight: map-get($map: $fontWeights, $key: "bold");
    line-height: 25px;
    @include textEllipsis(1);
}
.roleName {
    color: #010a10;
    opacity: 0.8;
    line-height: 20px;
    @include textEllipsis(1);
}

.profileCard_btnContainer {
    @include alignOption(row);
    justify-content: space-between;
}

.profileCard_imageWrap {
    border-radius: 50%;
    position: relative;
    .profileCard_activeGreendot {
        background-color: #00e171;
        border: solid 2px #fff;
        border-radius: 50%;
        position: absolute;
        width: 13px;
        height: 13px;
        bottom: 9px;
        right: 0;
        display: block;
    }
}

@media (min-width: $desktopBreakpointMinWidth) {
    .profileCard_imageWrap {
        .profileCard_activeGreendot {
            bottom: 10px;
            right: 6px;
        }
    }
}

// DesktopSearchCard
.rbt-input-main.form-control.rbt-input {
    // font-weight: map-get($map: $fontWeights, $key: "extraBold");
    // font-family: var(--font-medium);
    font-size: #{calcRem(22)};
    letter-spacing: #{calcRem(-0.22)};
    color: #1053ff;
    // height: 58px !important;
    &::placeholder {
        // font-weight: map-get($map: $fontWeights, $key: "normal");
        font-size: #{calcRem(20)};
        color: #80868b;
        // padding-left: 10px;
    }
}

.srcSearch .rbt-menu.dropdown-menu.show {
    left: -30px !important;
}

.srcSearch__Sticky .rbt-menu.dropdown-menu.show {
    left: 0 !important;
}

.destSearch .rbt-menu.dropdown-menu.show {
    left: -20px !important;
}

.destSearch__Sticky .rbt-menu.dropdown-menu.show {
    left: -20px !important;
}

.rbt-menu.dropdown-menu.show {
    top: #{calculatePercent(30)} !important;
    // left: -40px !important;
    // &#transporter-destination-search {
    //     // left: 0 !important;
    // }
    border-radius: 12px;
    width: 563px !important;
    max-width: 563px !important;
    min-width: unset !important;
    max-height: #{calculatePercent(369)} !important;
    border: 1px solid #e6e7e8;
    box-shadow: 0px 0px 20px #cdcdcd66;

    .dropdown-item {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.destination-search_input-wrapper {
    animation: paint 0.7s forwards;
}

@keyframes paint {
    0% {
        right: -340px;
        width: 490px;
        z-index: 1;
        background-color: white;
        display: none !important;
        // visibility: hidden;
        // opacity: 1;
    }
    100% {
        left: 0;
        width: 732px;
        // visibility: unset;
        opacity: 1;
        display: unset !important;
        background-color: white;
        cursor: pointer;
        z-index: 1;
    }
}
