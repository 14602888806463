@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

$collapsedSidebarWidth: 70px;
$expandedSidebarWidth: 250px;

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

//Base style
.mainLayoutWrapper {
    display: flex;
    min-height: 100vh;

    //left sidebar wrapper
    //Sidebar Expanded style
    .sideBarWrapper {
        z-index: 999;
        position: sticky;
        top: 0;
        height: 100vh;
        width: 100%;
        max-width: $expandedSidebarWidth;
        transition: width 300ms;

        .navItemsContainer {
            // display: flex;
            // flex-direction: column;
            // height: 100%;
        }

        &.isSideBarExpanded,
        &:hover {
            width: 100%;
            min-width: $expandedSidebarWidth;
            transition: width 0.5s;

            .userProfileIconContainer {
                .userProfileLabelContainer {
                    .userProfileLabel {
                        display: block;
                    }
                }
            }
        }
    }

    //left sidebar wrapper
    .sideBarWrapper {
        background-color: var(--primary-color);
        padding: 15px;

        //Logo container
        .logoWrapper {
            @include flex-center();
            margin: 25px 0;

            .logoContainer {
                display: flex;
                flex-direction: column;
                cursor: pointer;
            }
        }

        //User Profile icon
        .userProfileIconContainer {
            cursor: pointer;

            position: absolute;
            bottom: 10px;
            left: 15px;
            .userProfileLabelContainer {
                display: flex;
                gap: 10px;
                align-items: center;

                .userProfileLabel {
                    display: none;
                }
            }
            .dropdownToggleClassName {
            }
            .dropdownMenuItemClassName {
                top: -100px !important;
                padding: 10px;
                border-radius: 6px;
                left: 20%;
                box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.25);

                .userProfileButtonWrapper {
                    @include flex-center();
                    flex-direction: column;
                    gap: 5px;

                    button {
                        justify-content: left;
                        padding: 5px;

                        &:hover {
                            background-color: #2355fc;
                            color: #ffffff;
                            transition: color background-color 500ms;

                            svg {
                                path {
                                    stroke: #ffffff;
                                    fill: #ffffff;
                                }
                            }
                        }
                    }
                }
            }
        }

        //Posting button container
        .postingMenuDropdownContainer {
            .dropdownToggleClassName {
            }
            .dropdownMenuItemClassName {
                top: 0 !important;
                padding: 10px;
                border-radius: 6px;
                left: 110%;
                box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.25);

                .postingButtonWrapper {
                    @include flex-center();
                    flex-direction: column;
                    gap: 10px;
                    width: max-content;

                    button {
                        justify-content: left;
                        padding: 15px;

                        &:hover {
                            background-color: #2355fc;
                            color: #ffffff;
                            transition: color background-color 500ms;

                            svg {
                                path {
                                    stroke: #ffffff;
                                    fill: #ffffff;
                                }
                            }
                        }
                    }
                }
            }
        }

        //Single posting button
        .postingBtnContainer {
            margin-bottom: 10px;
            cursor: pointer;
            .postingBtn {
                background-color: #fff;
                display: flex;
                border-radius: 5px;
                align-items: center;
                .postingLabel {
                }
            }
        }

        //Popup posting button
        .postingIconContainer {
            .postingLabelContainer {
                background-color: #fff;
                border-radius: 5px;
                .postingLabel {
                    display: inline-flex;
                }
            }
            padding-bottom: 10px;
            cursor: pointer;
        }
        //MenuItems
        .menuItemsWrapper {
            .menuItems {
                list-style: none;
                margin-left: -15px;
                margin-right: -15px;
                padding: 0;

                max-height: 75vh;
                height: 100%;
                overflow: auto;

                //Single Item
                .menuItemWrapper {
                    margin-bottom: 10px;

                    .menuItem {
                        @include flex-center();
                        justify-content: initial;
                        cursor: pointer;
                        height: 50px;
                        padding: 15px 0px 15px 25px;

                        .menuIcon {
                            svg {
                                width: 22px;
                                height: 26px;
                                margin-top: 3px;
                            }
                        }

                        .label {
                            font-size: calcRem(16);
                            padding-left: 20px;
                            color: #fff;

                            @include flex-center();
                            justify-content: space-between;
                            gap: 10px;

                            &.arowDown {
                                svg {
                                    transition: transform 500ms;
                                }
                            }
                        }
                    }

                    // &.active::before {
                    //     content: "";
                    //     display: block;
                    //     width: 4px;
                    //     height: 50px;
                    //     background-color: #fff;
                    //     position: absolute;
                    //     left: 0;
                    //     border-bottom-right-radius: 4px;
                    //     border-top-right-radius: 4px;
                    //     opacity: 1;
                    //     transition: opacity 1.5s;
                    // }
                    &.disableClick {
                        .menuItem {
                            cursor: unset;
                        }
                    }
                    &.active {
                        .menuItem {
                            position: relative;
                            background-color: #0f40c8;
                            transition: background-color 0.3s linear;
                            .label {
                                &.arowDown {
                                    svg {
                                        transform: rotate(90deg);
                                        transition: transform 500ms;
                                    }
                                }
                            }
                            &::before {
                                content: "";
                                display: block;
                                width: 4px;
                                height: 50px;
                                background-color: #fff;
                                position: absolute;
                                left: 0;
                                border-bottom-right-radius: 4px;
                                border-top-right-radius: 4px;
                                opacity: 1;
                                transition: opacity 1.5s;
                            }
                        }
                    }
                }
            }

            .subMenuItems {
                list-style: none;

                height: 0;
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s, opacity 0.5s linear;
                display: none;
                &.showSubmenu {
                    height: 100%;
                    visibility: visible;
                    opacity: 1;
                    display: block;
                }

                .subMenuItem {
                    @include flex-center();
                    justify-content: initial;
                    cursor: pointer;
                    height: 50px;
                    padding: 15px 0px 15px 25px;

                    .subMenuIcon {
                        svg {
                            width: 22px;
                            height: 26px;
                            margin-top: 3px;
                        }
                    }

                    .subMenuLabel {
                        font-size: calcRem(14);
                        padding-left: 20px;
                        color: #afc5ff;
                        @include flex-center();
                        justify-content: space-between;
                        gap: 10px;
                    }

                    &.subMenuActive {
                        .subMenuLabel {
                            color: #fff;

                            &.arowDown {
                                svg {
                                    transform: rotate(90deg);
                                    transition: transform 500ms;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //Right side content wrapper
    .siteMainContent {
        position: relative;
        max-width: calc(100vw - 250px);
        width: 100%;
    }
}

//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
}

// mobile to desktop screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
    .mainLayoutWrapper {
        //Sidebar Expanded style
        .sideBarWrapper {
            width: $collapsedSidebarWidth;
            transition: width 300ms;

            &:hover,
            &.isSideBarExpanded {
                width: 100%;
                min-width: $expandedSidebarWidth;
                transition: width 0.5s;
            }
        }

        //Sidebar content style
        //left sidebar wrapper
        .sideBarWrapper {
            &:hover,
            &.isSideBarExpanded {
                //Single posting button
                .postingBtnContainer {
                    .postingBtn {
                        background-color: #fff;
                        display: block;
                        .postingLabel {
                            display: inline-flex;
                        }
                    }
                }

                //Posting button container
                .postingIconContainer {
                    .postingLabelContainer {
                        .postingLabel {
                            display: inline-flex;
                        }
                    }
                }
                //MenuItems
                .menuItemsWrapper {
                    .menuItems {
                        //Single Item
                        .menuItemWrapper {
                            .menuItem {
                                .label {
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    .subMenuItems {
                        &.showSubmenu {
                            height: 100%;
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
            }

            //Single posting button
            .postingBtnContainer {
                .postingBtn {
                    background-color: #fff;
                    display: contents;
                    .postingLabel {
                        display: none;
                    }
                }
            }

            //Posting button container
            .postingIconContainer {
                .postingLabelContainer {
                    .postingLabel {
                        display: none;
                    }
                }
            }
            //MenuItems
            .menuItemsWrapper {
                .menuItems {
                    //Single Item
                    .menuItemWrapper {
                        .menuItem {
                            .label {
                                visibility: hidden;
                                opacity: 0;
                                transition: visibility 0s, opacity 0.5s linear;
                            }
                        }
                    }
                }
                .subMenuItems {
                    height: 0;
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0s, opacity 0.5s linear;
                    &.showSubmenu {
                        height: 0;
                        visibility: hidden;
                        opacity: 0;
                        transition: visibility 0s, opacity 0.5s linear;
                    }

                    .subMenuItem {
                        @include flex-center();
                        justify-content: initial;
                        cursor: pointer;
                        height: 50px;
                        padding: 15px 0px 15px 25px;

                        .subMenuIcon {
                            svg {
                                width: 22px;
                                height: 26px;
                                margin-top: 3px;
                            }
                        }

                        .subMenuLabel {
                            font-size: calcRem(14);
                            padding-left: 20px;
                            color: #afc5ff;
                        }

                        &.subMenuActive {
                            .subMenuLabel {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }

        //Right side content wrapper
        .siteMainContent {
            max-width: calc(100vw - 70px);
            width: 100%;
        }
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .mainLayoutWrapper {
        //Sidebar Expanded style
        .sideBarWrapper {
            width: 0px;
            min-width: 0;
            padding: 0;
            overflow: hidden;
            transition: width 300ms;

            position: fixed;

            &.isSideBarExpanded {
                width: 100%;
                min-width: $expandedSidebarWidth;
                transition: width 0.5s min-width 0.5s linear;
            }
        }

        //Right side content wrapper
        .siteMainContent {
            max-width: unset;
            width: 100%;
            padding-bottom: 60px;
        }
    }
}
