@mixin textEllipsis($lines: 1) {
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
}

@mixin hideScrollbar {
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@mixin scrollBar {
    &::-webkit-scrollbar {
        width: 18px;
    }
    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0px 0px 10px 10px #e6e6e6;
        border: 5px solid transparent;
        border-radius: 16px;
    }
}
