//
// footer.scss
//

@import "@vahak/core/dist/scss/variables";

@mixin spacing {
    padding-top: 30px;
    padding-bottom: 30px;
}

@mixin bottomSpacing {
    padding-top: 0;
    padding-bottom: 30px;
}

@mixin topSpacing {
    padding-top: 30px;
    padding-bottom: 0px;
}

@mixin removeSpacing {
    padding-top: 0px;
    padding-bottom: 0px;
}

.footer {
    p {
        opacity: 0.6;
        font-family: var(--font-regular);
        color: white;
    }
    .link__highlighted {
        text-decoration: underline;
        color: #89a9ff;
    }
}

.footer {
    background: $footer;
    position: relative;
    color: $gray-500;

    .divider {
        border-top: 1px solid #ffffff;
        opacity: 0.4;
    }

    .row {
        &.topLinks {
            @include topSpacing;
        }

        &.getInTouch {
            @include spacing;

            .footerPlaystoreIcon {
                height: 50px;
                max-width: 165px;
            }
        }

        &.mostSearchedLinkContainer {
            padding-top: 24px;
            padding-bottom: 24px;
        }

        .trustContent {
            @include spacing;

            p:nth-child(2) {
                margin-bottom: 0;
            }

            u {
                color: #ffffff;
            }
        }

        .socialIconWrapper {
            background-color: #20294e;
            border-radius: 8px;
            height: 50px;
            width: 300px;
            padding: 0 10px 0 10px;

            .socialIconContainer {
                display: flex;
                justify-content: space-around;
                align-items: center;
                height: 100%;

                .fillWhite {
                    fill: white;
                }
                a {
                    cursor: pointer;
                }
            }
        }

        p {
            font-size: 14px;
        }

        ul {
            li {
                a {
                    font-size: 14px;
                }
            }
        }
    }
    .logoFooter {
        color: $light;
        font-weight: map-get($map: $fontWeights, $key: "semiBold");
        font-size: 24px;
        padding: 3px 0;
        letter-spacing: 1px;
        display: block;
        margin-bottom: 5px;
        height: 75px;
        svg {
            position: relative;
            left: -7px;
        }
    }
    .footerHead {
        color: #e8e8e8;
        font-size: 16px;
        font-family: var(--font-semiBold);
        font-weight: normal;
        margin-bottom: 15px;
    }
    .textFoot {
        color: $gray-500;
    }
    .footerList {
        margin-bottom: 0;
        list-style: none;
        margin-top: 1.4rem;
        padding-left: 0;

        li {
            margin-bottom: 10px;
            a {
                color: #adb5bd;
                transition: all 0.5s ease;

                .hiring {
                    padding: 0 5px 0 5px;
                    background-color: #0f53fb;
                    height: 18px;
                    width: 80px;
                    border-radius: 4px;
                    font-size: 12px;
                    color: #ffffff;
                }
                &:hover {
                    color: lighten($gray-500, 20%);
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &.footerBar {
        .row {
            padding-top: 22px;
            padding-bottom: 22px;
        }
        .copyRights {
            display: flex;
            align-items: center;
            height: 100%;
            p {
                margin-bottom: 0;
            }
        }
        .madeInIndia {
            background-color: #20294e;
            width: 180px;
            height: 40px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            opacity: 1;
            color: #ffffff;

            p {
                margin-bottom: 0;
            }
        }
        .payments {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            p {
                margin-bottom: 0;
            }
            img {
                margin-left: 10px;
            }
        }
    }
}

@media (max-width: 767px) {
    .footer {
        .divider {
            border-top: 1px solid #ffffff;
            opacity: 0.4;
        }

        .row {
            &.topLinks {
                .col {
                    @include bottomSpacing;
                }
            }

            &.getInTouch {
                @include removeSpacing;
                .col {
                    @include bottomSpacing;
                }
            }

            &.mostSearchedLinkContainer {
                padding-top: 24px;
                padding-bottom: 24px;
            }

            .trustContent {
                @include spacing;
            }

            .col {
                .socialIconWrapper {
                    width: 100%;
                }
            }
        }
        &.footerBar {
            .row {
                @include removeSpacing;
                @include topSpacing;
                .col {
                    @include bottomSpacing;
                    p {
                        padding-bottom: 0;
                        font-size: 10px;
                    }

                    .copyRights {
                        justify-content: center;
                        p {
                            margin-bottom: 0;
                        }
                    }

                    .payments {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        p {
                            margin-bottom: 0;
                        }
                        img {
                            margin-left: 10px;
                        }
                    }

                    .madeInIndia {
                        background-color: #20294e;
                        width: 180px;
                        height: 40px;
                        margin-left: auto;
                        margin-right: auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 8px;

                        p {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
