@import "./bootstrap-overrides";
@import "./skeleton-overrides";
@import "./sticky-overrides";
@import "./rbt-overrides";
@import "./components";

:root {
    --font-regular: "Inter-Regular";
    --font-medium: "Inter-Medium";
    --font-bold: "Inter-Bold";
    --font-semiBold: "Inter-SemiBold";
    --primary-color: #0f53fb;
    --secondary-color: #08123b;
    --white-color: #ffffff;
    --black-color: #000000;
    --red-color: #ff0000;
    --brownish-grey: #666666;
    --light-blue: #00a1ff;
    --light-grey: #f6f6f6;

    // --font-bold: "Gilroy-Bold";
    // --font-extraBold: "Gilroy-ExtraBold";
}

body {
    color: #202124;
    font-family: var(--font-regular);
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
    font-family: var(--font-semiBold);
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
    font-family: var(--font-medium);
}

p {
    color: rgba(32, 33, 36, 0.9);
}

@media (max-width: $mobileBreakpointMaxWidth) {
    h1,
    .h1 {
        font-size: calcRem(24);
    }
    h2,
    .h2 {
        font-size: calcRem(18);
    }
    h3,
    .h3 {
        font-size: calcRem(16);
    }
    h4,
    .h4 {
        font-size: calcRem(12);
    }
    h5,
    .h5 {
        font-size: calcRem(10);
    }
    h6,
    .h6 {
        font-size: calcRem(8);
    }
}

@media (min-width: $desktopBreakpointMinWidth) {
    h1,
    .h1 {
        font-size: calcRem(32);
    }
    h2,
    .h2 {
        font-size: calcRem(24);
    }
    h3,
    .h3 {
        font-size: calcRem(20);
    }
    h4,
    .h4 {
        font-size: calcRem(16);
    }
    h5,
    .h5 {
        font-size: calcRem(14);
    }
    h6,
    .h6 {
        font-size: calcRem(12);
    }
}

a:hover {
    text-decoration: none;
}

@media (max-resolution: 2dppx) {
    :root {
        zoom: 1;
    }
}
// ::-webkit-scrollbar {
//     width: 10px;
//     height: 10px;
// }

// ::-webkit-scrollbar-track {
//     background: #f8f8f8;
//     box-shadow: inset 0 0 5px #f8f8f8;
//     -webkit-box-shadow: inset 0 0 5px #f8f8f8;
//     border-radius: 10px;
//     -webkit-border-radius: 10px;
//     border: 1px solid #cbd5e1;
// }

// ::-webkit-scrollbar-thumb {
//     background: #cbd5e1;
//     border-radius: 10px;
//     box-shadow: inset 0 0 5px #cbd5e1;
//     -webkit-box-shadow: inset 0 0 5px #cbd5e1;
//     -webkit-border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb:window-inactive {
//     background: rgba(59, 68, 82, 0.1);
// }

// ::-webkit-scrollbar-corner {
//     background: #f8f8f8;
// }
