@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.dropdownBtn {
    padding: 12px 20px;
    display: inline-flex;
    align-items: center;
}

.card {
    padding: 6px;
    display: inline-flex;
    align-items: center;
    gap: 20px;
    .cardImg {
        background-color: $grey-150;
        width: 100px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
    }

    &.active {
        .cardImg {
            background-color: $blue;
        }
    }
}

.main {
    @extend .card;
    padding: 20px;

    .list {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 300px;
    }
}
