@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    .submitBtn {
        margin-top: 10px;
    }
}

//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
    .main {
        width: 462px;
        padding: 20px;

        .successScreen {
            .imgContainer {
                width: 250px;
                height: 152px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
    .main {
        width: 462px;
        padding: 20px;

        .successScreen {
            .imgContainer {
                width: 250px;
                height: 152px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .main {
        .successScreen {
            .imgContainer {
                width: 134px;
                height: 81px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
