@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    min-height: 40px;
    background: #ffe9c2;

    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 40px;

        .actionBtn {
            cursor: pointer;
        }
    }
}

//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
}
