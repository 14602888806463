@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    position: fixed;
    inset: 0;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999999999999999;
    backdrop-filter: blur(10px);
    background-color: rgba(25, 25, 25, 0.6) !important;

    .content {
        overflow: hidden;
        background-color: white;
        height: min-content;
        width: min-content;
        border-radius: 12px;
        min-width: 320px;
        position: relative;
        .innerContent {
            padding: 12px 16px 18px 16px;
            button {
                margin-top: 6px;
            }
        }
        .closeIcon {
            position: absolute;
            top: 8px;
            right: 8px;
            cursor: pointer;
        }
    }
}
