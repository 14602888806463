@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.bottomNavbarWrapper {
    position: fixed;
    bottom: 0;
    height: 50px;
    width: 100vw;
    background: white;
    z-index: 11;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
}

.bodyContent {
    margin-top: 55px;
    margin-bottom: 55px;
}
